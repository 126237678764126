import React from "react"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const preSurgical = {
  heading: "Pre-Surgical",
  list: "<ul><li>Consult with your surgical Smile Team specialists at AOMS.</li><li>3D Cone Beam evaluation of bone with a surgical specialist: Dr. Graves, Dr. Wilkinson, or Dr. Gonzales. </li><li>Review all medical conditions and coordinate care with your medical doctor prior to surgery if needed. Please provide their contact information to AOMS. Medical conditions that need to be addressed include diabetes; heart conditions; high blood pressure; joint replacements; seizure disorders; lung, kidney, or liver disease, etc. </li><li>Consult with a restorative dentist to arrange restorative phase. Impressions are needed 3 weeks before surgery. </li><li>Schedule surgery and pay deposit. </li><li>Laboratory tech and restorative dentist will work together to design temporary teeth. (This may take more than one appointment.)</li></ul>"
}

const surgical = {
  heading: "Surgical",
  list: '<ul> <li><strong>Refer to Pre-Operative Instructions. Do not eat, drink, or smoke for 8 hours prior to surgery.</strong> </li><li>Please <strong>bring all medications</strong> with you for your surgical appointment (including insulin for diabetics). </li><li>As an added prevention against infection, please brush your teeth 3-4 times and swish with a mouthwash of your choice. </li><li>Be prepared to pay your final payment the day of surgery. Our Smile Team financial coordinator will be happy to assist you. We ask that all financial questions be addressed prior to your surgery date. (Go to <a href="/information/first-oral-surgery-visit-amarillo-tx/">Your First Visit</a> page to view financial arrangements and prepare accordingly.) </li><li>Plan to be with us 4–6 hours for the procedure.</li> </ul>'
}

const postSurgical = {
  heading: "Post-Surgical",
  list: "<ul> <li>2 days post-surgery: Appointment with restorative dentist to check your bite and check on your healing process. </li><li>2 weeks post-surgery: Follow-up with Dr. Graves or Dr. Wilkinson to evaluate the healing process and examine implants. </li><li>1 –2 months post-surgery: Follow-up with AOMS Smile Team to evaluate progress. </li><li>4–6 months post-surgery: Follow-up with AOMS for release to schedule with restorative dentist.</li> </ul>"
}

const restorative = {
  heading: "Restorative Phase",
  list: "<ul> <li>Appointment with restorative dentist to receive impressions for final bridge design. </li><li>3-4 weeks later: Appointment with restorative dentist for wax try-in to finalize for lab work. </li><li>3-4 weeks later: When lab work is complete, schedule placement of bridge with restorative dentist. </li><li>Regular check-ups with restorative dentist for hygiene and maintenance.</li> </ul>"
}

const theItems = [preSurgical, surgical, postSurgical, restorative]

function Timeline() {
  return (
    <div className="timeline color-back joshua-tree-content">
      <h2 className="has-text-centered">Timeline</h2>
      {theItems.map((item, i) => (
        <TimelineItem
          key={item.heading}
          heading={item.heading}
          list={item.list}
          number={i + 1}
        />
      ))}
    </div>
  )
}

function TimelineItem({ heading, list, number }) {
  return (
    <div className="timeline-item">
      <div className="columns">
        <div className="column is-5 has-text-centered-mobile">
          <span className="reason-number-circle">{number}</span>
        </div>

        <div className="column">
          <h5>{heading}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column is-5"></div>
        <div className="column">
          <MarkdownViewer markdown={list} />
        </div>
        <div className="column is-3"></div>
      </div>
    </div>
  )
}

export default Timeline

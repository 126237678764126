import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import marked from "marked"

marked.setOptions({
  renderer: new marked.Renderer(),
  smartLists: true,
})

const Text = ({
  text, // text content
  as = "div", // element type ex. "h1", "h2", "span" "p"...
  className,
  style,
  useStringReplace,
  ...rest
}) => {
  if (!text) return null
  const viewerClasses = classNames("marked", className)

  // superscript symbols
  let convertedText = text

  convertedText = removeSymbols(convertedText)

  if (as === "div") {
    // use marked to convert markdown if using default "div"
    convertedText = marked(convertedText)
  }

  return React.createElement(as, {
    style: style,
    className: viewerClasses,
    ...rest,
    dangerouslySetInnerHTML: { __html: convertedText },
  })
}

export default React.memo(Text)

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  as: PropTypes.string,
  style: PropTypes.object,
}

function removeSymbols(str) {
  str = str.replace(/[™®©]/g, "<sup>$&</sup>")
  let title = str.match(/title='(.*?)'/gi)
  if (title) {
    let newStr = str
    for (let i = 0; i < title.length; i++) {
      let newTitle = title[i].replace("<sup>", "").replace("</sup>", "")
      newStr = newStr.replace(title[i], newTitle)
    }
    return newStr
  } else {
    return str
  }
}
